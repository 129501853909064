import { ref } from 'vue';
import { defineStore } from 'pinia';
import { SidebarMenu } from '@pantaurelawan/shared/data-access/models';

export const useAppSidebarMenuStore = defineStore('appSidebarMenu', () => {
  const state = ref<SidebarMenu[]>([
    {
      text: 'Navigation',
      is_header: true,
    },
    {
      url: '/home',
      icon: 'bi bi-cpu',
      text: 'Dashboard',
    },
    {
      url: '/candidates',
      icon: 'bi bi-cpu',
      text: 'Kandidat',
    },
  ]);

  return { state };
});
