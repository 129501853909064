import { defineStore } from 'pinia';
import { ref } from 'vue';

const SHOW_FLASH_MESSAGE_FOR = 5000; // 5 detik

export const useFlashMessageStore = defineStore('flashMessageStore', () => {
  const show = ref(false);
  const title = ref('');
  const message = ref('');

  function flash(flashTitle: string, flashMessage: string, hideAfter = SHOW_FLASH_MESSAGE_FOR) {
    show.value = true;
    title.value = flashTitle;
    message.value = flashMessage;

    setTimeout(resetState, hideAfter);
  }

  function resetState() {
    show.value = false;
    title.value = '';
    message.value = '';
  }

  return { show, title, message, flash };
});
