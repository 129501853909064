import { RoleSetting, UserRoles } from '@pantaurelawan/shared/data-access/models';

export class Roles {
  // Role untuk koordinator pusat
  static Admin = 'admin';

  // Role untuk kandidat
  static Kandidat = 'kandidat';

  // Role untuk koordinator di lapangan. User yang dapat
  // menambahkan koordinator di level bawahnya dan
  // calon pemilih
  static Relawan = 'relawan';

  // Role untuk pendukung kandidat yang tidak memiliki
  // akun
  static CalonPemilih = 'calon_pemilih';

  // Role spesial yang dapat diberikan kepada relawan maupun calon pemilih
  // yang nantinya dapat mengakses fitur real count
  static Saksi = 'saksi';
}

export const BASE_ROLE_SETTINGS: Record<UserRoles, RoleSetting> = {
  koordinator_provinsi: {
    id: 'koordinator_provinsi',
    alias: 'Koordinator Wilayah',
    activated: false,
    wilayah_kerja: 'provinsi',
    urutan_dalam_struktur: 1,
    penambahan_harus_dari_dpt: false,
    pengaturan_form: {},
    hak_akses: {},
  },
  koordinator_kabupaten_kota: {
    id: 'koordinator_kabupaten_kota',
    alias: 'Koordinator Kabupaten/Kota',
    activated: false,
    wilayah_kerja: 'kabupaten_kota',
    urutan_dalam_struktur: 2,
    penambahan_harus_dari_dpt: false,
    pengaturan_form: {},
    hak_akses: {},
  },
  koordinator_kecamatan: {
    id: 'koordinator_kecamatan',
    alias: 'Koordinator Kecamatan',
    activated: false,
    wilayah_kerja: 'kecamatan',
    urutan_dalam_struktur: 3,
    penambahan_harus_dari_dpt: false,
    pengaturan_form: {},
    hak_akses: {},
  },
  koordinator_desa_kelurahan: {
    id: 'koordinator_desa_kelurahan',
    alias: 'Koordinator Kelurahan',
    activated: false,
    wilayah_kerja: 'desa_kelurahan',
    urutan_dalam_struktur: 4,
    penambahan_harus_dari_dpt: false,
    pengaturan_form: {},
    hak_akses: {},
  },
  koordinator_rw: {
    id: 'koordinator_rw',
    alias: 'Koordinator RW',
    activated: false,
    wilayah_kerja: 'rw',
    urutan_dalam_struktur: 5,
    penambahan_harus_dari_dpt: false,
    pengaturan_form: {},
    hak_akses: {},
  },
  koordinator_rt: {
    id: 'koordinator_rt',
    alias: 'Koordinator RT',
    activated: false,
    wilayah_kerja: 'rt',
    urutan_dalam_struktur: 6,
    penambahan_harus_dari_dpt: false,
    pengaturan_form: {},
    hak_akses: {},
  },
  koordinator_tps: {
    id: 'koordinator_tps',
    alias: 'Koordinator TPS',
    activated: false,
    wilayah_kerja: 'tps',
    urutan_dalam_struktur: 7,
    penambahan_harus_dari_dpt: false,
    pengaturan_form: {},
    hak_akses: {},
  },
  calon_pemilih: {
    id: 'calon_pemilih',
    alias: 'Dukungan',
    activated: true,
    urutan_dalam_struktur: 8,
    penambahan_harus_dari_dpt: true,
    pengaturan_form: {},
  },
};
