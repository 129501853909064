export class CandidateSetupPhases {
  static StrukturTimses = 'struktur_timses';

  static PengaturanForm = 'form_relawan';

  static KunciDPT = 'kunci_dpt';

  static HakAkses = 'hak_akses';

  static KategoriAktivitas = 'kategori_aktvitas';

  static Selesai = 'completed';

  static values = [
    this.StrukturTimses,
    this.PengaturanForm,
    this.KunciDPT,
    this.HakAkses,
    this.KategoriAktivitas,
    this.Selesai,
  ];
}
