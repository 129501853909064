import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { SuperadminLogin } from '@pantaurelawan/superadmin/auth/login/feature';

const routes: Array<RouteRecordRaw> = [
  { path: '/', redirect: '/login' },
  {
    path: '/login',
    name: 'Login',
    component: SuperadminLogin,
  },
  {
    path: '/home',
    name: 'Home',
    component: () =>
      import(
        /* webpackChunkName: "home" */
        '@pantaurelawan/superadmin/home/feature'
      ).then(m => m.SuperadminHome),
  },
  {
    path: '/candidates',
    name: 'CandidateList',
    component: () =>
      import(
        /* webpackChunkName: "candidate-list" */
        '@pantaurelawan/superadmin/candidates/feature/list'
      ).then(m => m.CandidateList),
  },
  {
    path: '/candidates/create',
    name: 'CandidateCreate',
    component: () =>
      import(
        /* webpackChunkName: "candidate-create" */
        '@pantaurelawan/superadmin/candidates/feature/create'
      ).then(m => m.CandidateCreate),
  },
  {
    path: '/candidates/:id/edit',
    name: 'CandidateEdit',
    component: () =>
      import(
        /* webpackChunkName: "candidate-detail" */
        '@pantaurelawan/superadmin/candidates/feature/edit'
      ).then(m => m.CandidateEdit),
  },
  {
    path: '/candidates/:id',
    name: 'CandidateDetail',
    component: () =>
      import(
        /* webpackChunkName: "candidate-detail" */
        '@pantaurelawan/superadmin/candidates/feature/detail'
      ).then(m => m.CandidateDetail),
  },
];

const router = createRouter({
  history: createWebHistory(process.env['BASE_URL']),
  routes,
});

export { router };
