import {
  getAuth,
  getIdTokenResult,
  signInWithEmailAndPassword,
  browserLocalPersistence,
  browserSessionPersistence,
  User,
} from 'firebase/auth';

export async function login(
  email: string,
  password: string,
  rememberMe = false,
  onSuccess?: (user: User) => void,
  onError?: (message: string) => void
) {
  const auth = getAuth();

  try {
    if (rememberMe) {
      auth.setPersistence(browserLocalPersistence);
    } else {
      auth.setPersistence(browserSessionPersistence);
    }

    const res = await signInWithEmailAndPassword(auth, email, password);
    const idTokenResult = await getIdTokenResult(res.user);
    const claims = idTokenResult.claims;

    if (!claims.superadmin) {
      auth.signOut();
      throw new Error('Only superadmin can login to this app');
    }

    if (onSuccess) {
      onSuccess(res.user);
    }
  } catch (error) {
    if (!onError) return;

    if (error instanceof Error) {
      onError(error.message);
    } else {
      onError(String(error));
    }
  }
}
