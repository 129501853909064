import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { Settings } from 'luxon';
import { Vue3ProgressPlugin } from '@marcoschulte/vue3-progress';
import PerfectScrollbar from 'vue3-perfect-scrollbar';
import DataTable from 'datatables.net-vue3';
import DataTablesLib from 'datatables.net-bs5';
import DataTablesFixedColumnsLib from 'datatables.net-fixedcolumns-bs5';

import '@marcoschulte/vue3-progress/dist/index.css';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import 'datatables.net-fixedcolumns-bs5/css/fixedColumns.bootstrap5.min.css';
import 'vue-select/dist/vue-select.css';
import './scss/styles.scss';

import { LUXON_LOCALE, LUXON_TIMEZONE } from '@pantaurelawan/shared/config';
import { router } from '@pantaurelawan/superadmin/shared/router';
import { SuperadminShell } from '@pantaurelawan/superadmin/shell';
import { initializeFirebase } from './firebase';

Settings.defaultLocale = LUXON_LOCALE;
Settings.defaultZone = LUXON_TIMEZONE;

DataTable.use(DataTablesLib);
DataTable.use(DataTablesFixedColumnsLib);

const app = createApp(SuperadminShell);

initializeFirebase();
app.use(createPinia());
app.use(router);
app.use(Vue3ProgressPlugin);
app.use(PerfectScrollbar);
app.mount('#app');
