import { initializeApp, getApps, getApp } from 'firebase/app';
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from 'firebase/app-check';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { FUNCTIONS_REGION } from '@pantaurelawan/shared/config';

const firebaseConfig = {
  apiKey: 'AIzaSyCEu1Tps8iq0uUaud9y1KPJ7Gw5UNNXLJc',
  authDomain: 'pantau-relawan-by-lamacca.firebaseapp.com',
  projectId: 'pantau-relawan-by-lamacca',
  storageBucket: 'pantau-relawan-by-lamacca.appspot.com',
  messagingSenderId: '362287322457',
  appId: '1:362287322457:web:c42c1af379c4a927fde848',
  measurementId: 'G-VRDSRWQWV2',
};

export function initializeFirebase() {
  const app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApp();
  const auth = getAuth(app);
  const firestore = getFirestore(app);
  const storage = getStorage(app);
  const functions = getFunctions(app, FUNCTIONS_REGION);

  if (process.env.NODE_ENV === 'production') {
    initializeAppCheck(app, {
      provider: new ReCaptchaEnterpriseProvider('6LcvX6EkAAAAANPZnQQNHnW7KECnIR1nemkALhN1'),
      isTokenAutoRefreshEnabled: true,
    });
  }

  if (process.env.NODE_ENV !== 'production') {
    connectAuthEmulator(auth, 'http://localhost:9099');
    connectFirestoreEmulator(firestore, 'localhost', 8080);
    connectStorageEmulator(storage, 'localhost', 9199);
    connectFunctionsEmulator(functions, 'localhost', 5001);
  }

  return app;
}
